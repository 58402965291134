.service{
    margin: 50px 10% 0 10%;
    justify-content: center;
    align-items: center;
    text-align: center;
    }
   
    .services{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 30px;
    }
    
    .left {
        min-width: 130px;
        color: rgb(0, 151, 50);
        font-size: 25px;
        margin-top: 0;
        padding-top: 0;
        display: flex;
        justify-content: flex-start; /* Align text to start from the top */
        align-items: center; /* Center horizontally */
        flex-direction: column; /* Stack elements vertically if needed */
        height: 100%; /* Take the full height of the container */
        font-weight: 700;
    }
    
    .right{
        text-align: left;
        max-width: 60%;
        background-color: rgb(203, 250, 218);
        padding-left: 15px;
        font-size: 15px;
        border-radius: 5px;
        padding-right: 5px;
        font-weight: 500;
    }
    
    @media(max-width: 750px){
        .BenefitSec{
            margin: 0 5% 0 5%;
        }
    
        .left{
            font-size: 22px;
        }
    }
    
    @media(max-width: 600px){
    
        .services{
            flex-direction: column;
        }
    
        .left{
            font-size: 22px;
        }
    
        .right{
            font-size: 15px;
            max-width: 100%;
        }
    
        .services-1{
            flex-direction: column-reverse;
        }
    }