.BenefitSec {
    margin: 0 10%;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.BenefitSec h1 {
    color: rgb(0, 151, 50);
    font-size: 35px;
}

.BenefitSec h4 {
    color: rgb(1, 61, 21);
    font-size: 25px;
}

.benefit {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 40px;
}

.left {
    min-width: 100px;
    color: rgb(0, 151, 50);
    font-size: 25px;
    margin-top: 0;
    padding-top: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 100%;
    font-weight: 700;
}

.right {
    text-align: left;
    max-width: 80%;
    background-color: #cbfada;
    padding-left: 10px;
    border-radius: 5px;
    padding-right: 5px;
}

.right p{
    font-size: 20px;
 
}
@media(max-width: 750px) {
    .BenefitSec {
        margin: 0 5%;
    }

    .left {
        font-size: 22px;
    }
}

@media(max-width: 600px) {
    .benefit {
        flex-direction: column;
    }

    .left {
        font-size: 22px;
    }

    .right {
        font-size: 15px;
        max-width: 100%;
    }

    .benefit-1 {
        flex-direction: column-reverse;
    }
}
