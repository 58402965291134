.scroll-container {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    width: 100%; 
    margin-top: 18px;
    padding: 0;
    height: 35px;
    background-color: #202920;
    border: none;
    position: relative; /* Ensure that the container is positioned correctly */
  }
  
  .scroll-container:hover p {
    animation-play-state: paused; /* Pause the animation on hover */
  }
  
  .scroll-container p {
    display: inline-block;
    padding: 0 20px;
    font-size: 20px; /* Adjust the size as needed */
    animation: scroll-horizontal 10s linear infinite;
    font-weight: 500;
    white-space: nowrap; 
    color: rgb(203, 250, 218);
  }
  
  @keyframes scroll-horizontal {
    0% {
      transform: translateX(300%); /* Start fully off-screen to the right */
    }
    100% {
      transform: translateX(-100%); /* Move off-screen to the left */
    }
  }
  
