@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

.main {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 80vh;
    height: auto;
    max-height: 1000px;
    background-image: url(/images/bg.jpeg); /* Path relative to public directory */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.main::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(110, 161, 118, 0.7); /* 70% opacity */
    z-index: 1; /* Ensures it's behind the content */
}

.main h1 {
    position: relative;
    z-index: 2; /* Ensures it's above the overlay */
    color: #ffffff; /* Text color for better contrast */
    font-size: 6rem; /* Adjust size as needed */
    text-align: center;
    text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -.5px .5px 0 #000, .5px .5px 0 #000; /* Outline effect */
    font-family: 'Times New Roman', Times, serif;
    margin-bottom: 10px; /* Reduce gap */
}

.main h5 {
    position: relative;
    z-index: 2; /* Ensures it's above the overlay */
    color: black; /* Text color for better contrast */
    font-size: 40px; /* Adjust size as needed */
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    margin-top: 0; /* Remove unnecessary top margin */
}

@media(max-width: 800px) {
    .main h1 {
        font-size: 4rem;
    }
}

@media(max-width: 545px) {
    .main h5 {
        font-size: 30px; /* Adjust size as needed */

    }
}

@media(max-width: 405px) {
    .main h5 {
        font-size: 25px; /* Adjust size as needed */

    }
}

@media(max-width: 385px) {
    .main h1 {
        font-size: 3rem;
        text-shadow: -1px -1.5px 0 #000, 1.5px -1.5px 0 #000, -.5px .5px 0 #000, .5px .5px 0 #000; /* Outline effect */

    }
}
