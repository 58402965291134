.about-main {
    text-align: center;
    height: 25vh;
    background-color: #b9e3b9;
    padding-top: 13vh;
    color: #2e482e;
}

.aboutDesc{
    margin: 30px 15%;
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
}

@media (max-width: 600px){
    .aboutDesc{
        margin: 30px 8%;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
    }

}