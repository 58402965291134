.popup {
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 300px;
    z-index: 9999;
    border-radius: 5px;
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.popup.show {
    opacity: 1;
    transform: translateX(0);
}

.popup-content {
    background-color: #d6f5e0;
    height: 300px;
    /* padding: 20px; */
    border-radius: 5px;
    font-size: 15px;
    font-weight: 600;
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
}

.popup-btn {
    cursor: pointer;
    background: rgb(0, 151, 50);
    border-radius: 7px;
    border: none;
    font-size: 15px;
    padding: 5px 10px;
    color: #cbfada;
    text-align: center;
    display: inline-block;
}

@media(max-width: 500px) {
    .popup {
       
        width: 250px;
    }
}