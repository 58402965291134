/* General Navigation Styles */
nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 20px 80px;
    box-shadow: 0 5px 15px rgba(130, 130, 130, 0.6);
    transition: 0.3s;
    margin-bottom: -18px;
  }
  
  nav div {
    z-index: 1000;
  }
  
  /* Navbar List Styles */
  #navbar {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  #navbar li {
    list-style: none;
    padding: 0 20px;
    position: relative;
  }
  
  #navbar li a {
    text-decoration: none;
    font-size: 1.3rem;
    font-weight: 600;
    color: gray;
    transition: 0.3s ease-in-out;
  }
  
  #navbar li a:hover,
  #navbar li a.active {
    color: #2e482e;
  }
  
  #navbar li a:hover::after,
  #navbar li a.active::after {
    content: "";
    width: 30%;
    height: 2px;
    background: #2e482e;
    position: absolute;
    bottom: -4px;
    left: 20px;
  }
  
  /* Mobile Menu Styles */
  #mobile {
    display: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 24px; /* Adjust size as needed */
    color: #2e482e; /* Ensure color is visible */
    position: absolute; /* Or fixed, depending on your layout */
    right: 20px; /* Adjust as needed */
  }
  
  #bar {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Font Awesome Icon Styling */
  .fas {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900; /* Necessary for solid icons */
  }
  
  /* Mobile Menu Responsive Styles */
  @media(max-width: 800px) {
    nav {
      padding: 20px 40px;
    }
  }
  
  @media(max-width: 700px) {
    nav {
      padding: 20px;
    }
  
    #navbar {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      position: fixed;
      top: 72.5px; 
      right: -350px;
      width: 300px;
      height: 100vh;
      background: #b9e3b9;
      padding: 40px  0 50% 10px;
      transition: 0.3s;
    }

    #navbar li a:hover::after,
    #navbar li a.active::after {
      content: "";
      width: 30%;  /* Adjust the width of the underline */
      height: 2px;
      background: #2e482e; /* Color of the underline */
      position: absolute;
      bottom: -5px;
      left: 35%; /* Center the underline by adjusting left */
      transition: width 0.3s ease-in-out;
    }
  
    #navbar.active {
      right: 0;
    }
  
    #navbar li {
      margin-bottom: 25px;
      padding-left: 125px;
      padding-right: 125px;
    }
  
    #navbar li a {
      color: #2e482e;
      font-weight: 700;
    }
  
    #mobile {
      display: block;
      font-size: 30px;
      cursor: pointer;
    }
  }
  
  /* Header Styling */
  nav h1 {
    color: #2e482e;
    cursor: pointer;
    font-family: 'Times New Roman', Times, serif;
    text-transform: uppercase;
    transition: 0.3s;
  }
  
  nav h1 span {
    color: black;
    transition: 0.3s;
  }
  
  nav a {
    text-decoration: none;
    transition: 0.3s;
  }
  