.heading1 {
    font-size: 40px;
    text-align: center;
    /* margin-top: 30px; */
    font-family: 'Times New Roman', Times, serif;
    margin-top: 50px;
    color: rgb(170, 107, 228);

}

.heading1 h4{
    margin-top: -40px;
}

.contact-form1 {
    color: rgb(228, 228, 228);
    font-size: 20px;
    margin-bottom: 2rem;
    font-weight: 600;
    text-align: center;
    font-size: 60px;
    padding-bottom: 70px;
    padding-top: 30px;
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.contact-form1 form {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-form1 form input,
.contact-form1 form textarea {
    border: none;
    outline: none;
    width: 80%;
    padding: 18px;
    background: rgb(203, 250, 218);
    color: #000;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    border-radius: 0.5rem;
}

.contact-form1 textarea {
    /* resize: none; */
    height: 55px;
}

.contact-form1 form .send1 {
    display: inline-block;
    padding: 14px 60px;
    background: #2e482e;
    border-radius: 40px;
    font-size: 30px;
    color: rgb(203, 250, 218);
    letter-spacing: 1px;
    text-decoration: none;
    font-weight: 600;
    animation: forwards;
    animation-delay: .5s;
    box-shadow: 0 0 5px rgb(95, 185, 122), 0 0 25px rgb(71, 174, 105);
    cursor: pointer;
    font-family: 'Times New Roman', Times, serif;
    width: 60%;
}

.contact-form1 form .send1:hover {
    background-color: rgb(0, 151, 50);
    color: #2e482e;
}


@media (max-width:700px){
    .heading1{
        font-size: 30px;
    }

    .contact-form1 {
        margin-left: 0;
        margin-right: 0;
    }
}


@media (max-width:500px){
    .heading1{
        font-size: 25px;
    }
    .contact-form1 form .send1 {
        font-size: 25px;
    }
}

