/* Flexbox to center content */
.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    padding: 20px; /* Padding for smaller screens */
    box-sizing: border-box;
  }
  
  /* Responsive iframe styles */
  .google-form {
    width: 100%;
    max-width: 800px; /* Limits max width */
    height: 100vh; /* Make height relative to viewport */
    border: none; /* Remove borders */
  }
  
  