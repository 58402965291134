.footer{
margin-top: 100px;
    display: flex;
    justify-content: space-between;
    padding: 20px 10%;
    background-color: rgb(0, 151, 50);
    flex-wrap: wrap;
    color: rgb(5, 48, 19);
}

.logo{
  min-width: 185px;
    /* background-color: brown; */
}

.links{
    /* background-color: azure; */
    min-width: 170px;
    margin: 2px;
}

.links h4{
font-size: 20px;
margin-top: 10px;
margin-bottom: 10px;
}
.footer ul {
    list-style-type: none; /* Removes bullet points */
    padding: 0; /* Removes default padding */
    margin: 0; /* Removes default margin */
}

.footer li {
    margin-bottom: 10px; 
}

.footer ul li a {
    text-decoration: none; /* Removes underline from link text */
    color: rgb(187, 238, 204);
    cursor: pointer;
    font-weight: 600;
  }

  .fa-brands, .fa-regular{
    color: rgb(5, 48, 19);
    font-weight: 600;
  }
