.intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    border-left: 1.5px solid rgb(0, 151, 50); /* Left border */
    border-right: 1.5px solid rgb(0, 151, 50); /* Right border */
    border-bottom: 1.5px solid rgb(0, 151, 50); /* Bottom border */
    border-top: 0; /* No top border */
    border-radius: 8px; 
    padding-bottom: 20px;
}


.intro .motive {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: rgb(0, 151, 50);
    width: 100%;
    /* Ensure it takes up the full width of its parent */
    padding: 20px;
    /* Add some padding for spacing */
}

.motive div {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;


}

.comp {
    /* background: #ff7474; */
    height: 150px;
    width: 200px;
    color: #012d01;
}

.motive div p {
    font-size: 20px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 700;

}

.fa-solid {
    font-size: 30px;
    padding-right: 8px;
    font-weight: 600;
}


@media(max-width: 1100px) {
    .motive div {
        flex-direction: column;
    }
}

@media(max-width: 850px) {
    .intro .motive {
        flex-wrap: wrap;
    }

    .motive div {
        flex-direction: column;
    }

    .comp {
        width: 300px;
    }
}

@media(max-width: 800px) {
    .comp {
        width: 250px;
    }
}

@media(max-width: 675px) {
    .comp {
        width: 200px;
    }
}

.brief {
    width: 100%;
    padding: 0 10px 10px 5%;
    /* border: 1px solid #ccc;  */
    box-sizing: border-box; /* Ensures padding and border are included in width */
}

.brief p{
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 30px;

}




.brief button {
    background-color: rgb(0, 151, 50);
    border: none;
    height: auto;
    padding: 4px 10px 4px 10px;
    font-size: 20px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
}

.brief button:hover{
    background-color: rgb(8, 116, 44);
    font-size: 21px;

}